export const translateText = (): void => {
    const inputField = document.getElementById('userInput') as HTMLInputElement;
    const outputParagraph = document.getElementById('output') as HTMLParagraphElement;
    const max_length = 500;
  
    const replacements: { [key: string]: string } = {
      'a': '4',
      'b': '8',
      'c': 'C',
      'd': 'D',
      'e': '3',
      'f': 'F',
      'g': '6',
      'h': 'H',
      'i': '1',
      'j': 'J',
      'k': 'K',
      'l': '1',
      'm': 'M',
      'n': 'N',
      'o': 'Ø',
      'p': 'P',
      'q': 'Q',
      'r': 'R',
      's': '5',
      't': '7',
      'u': 'U',
      'v': 'V',
      'w': 'W',
      'x': 'X',
      'y': 'Y',
      'z': 'Z',
    };
  
    const inputText = inputField.value;

    if (inputText.length > max_length) {
        alert(`Please limit the input to ${max_length} characters.`);
        return;
      }

    const regex = new RegExp(Object.keys(replacements).join('|'), 'gi');
  
    const transformedText = inputText.replace(regex, (matched) => {
      const lowerMatched = matched.toLowerCase();
  
      if (lowerMatched in replacements) {
        return matched === matched.toUpperCase()
          ? replacements[lowerMatched].toUpperCase()
          : replacements[lowerMatched];
      }
      return matched;
    });
  
    if (outputParagraph) {
      outputParagraph.innerText = transformedText;
    }
  };
  