import React from 'react'
import {translateText} from './script'

const Hero = () => {
  return (
    <div>
        <div className='grid-cols-1 justify-center'>
          
          <div className='text-center'>
            <h1 className='text-[#00df9a] font-bold p-2 text-4xl'>
            LEET TRANSLATOR
            </h1>
          </div>
          
          <div className='relative flex mx-auto max-w-[26rem] w-full h-[40rem]  flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-lg'>
            <p id='output' className='text-black w-full h-full px-8 text-justify p-4 overflow-auto'>
            1337
            </p>
          </div>
          
          <input type="text" placeholder='Text To Translate' id='userInput' className='bg-white max-w-[26rem] w-full rounded-md mx-auto flex my-4'/>

          <button className='bg-[#00df9a] flex mx-auto w-[200px] rounded-md font-medium my-6 py-3 text-black items-center justify-center' onClick={translateText}>Translate</button>

        </div>

    </div>
  )
}

export default Hero