import React from 'react';
import Hero from './components/hero';
import Navbar from './components/navbar';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />

    </div>
  );
}

export default App;
